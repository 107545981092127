import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import languages, { supportedLanguages } from './i18n';

import Home from './pages/Home';
import Faq from './pages/Faq';
import Desafio from './pages/Desafio10k';
import Shop from './pages/Shop';

console.log(languages);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: languages,
    supportedLngs: supportedLanguages,
    fallbackLng: 'en',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
  });

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/faq">
        <Faq />
      </Route>
      <Route path="/desafio10k">
        <Desafio />
      </Route>
      <Route path="/plans">
        <Shop />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default App;
