[
  {
    "name": "Free",
    "price": 0,
    "queries": 1,
    "stars": 0
  },
  {
    "name": "Premium",
    "price": 99.99,
    "queries": 25,
    "stars": 5,
    "spotlight": true
  },
  {
    "name": "Basic",
    "price": 39.99,
    "queries": 5,
    "stars": 1
  },
  {
    "name": "Gold",
    "price": 79.99,
    "queries": 15,
    "stars": 3
  }
]
