import en from './en.json';
import pt from './pt.json';
import es from './es.json';
import ru from './ru.json';

const languages = { en, pt, es, ru };

export default languages;

export const supportedLanguages = Object.keys(languages);
