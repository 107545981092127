{
    "translation": {
      "lobbyze": "Lobbyze",
      "logoLobbyze": "Logotipo Lobbyze",
      "features": "Funciones",
      "video": "Vídeo",
      "plans": "Planes",
      "pricing": "Precios",
      "contact": "Contacto",
      "login": "Inicia sesión",
      "tryItFree": "Pruébalo Gratis",
      "slide": [
        {
          "title": "La parrilla de torneos de los <b>sitios más grandes del mundo</b> en un solo lugar.",
          "description": "Tenga acceso a una extensa lista de torneos de los sitios web más grandes del mundo."
        },
        {
          "title": "Configure <b>notificaciones personalizadas</b> al registrarse.",
          "description": "Es posible crear notificaciones push para que se les recuerde que deben registrarse en el torneo. Esta notificación está personalizada para recordar minutos antes, minutos después del inicio del torneo, etc."
        },
        {
          "title": "Gestiona <b>tus</b> de ganancias con nuestros paneles personalizados.",
          "description": "Sigue la evolución de tu Bankroll a través de lanzamientos diarios de cómo era tu rutina y visualiza lo acumulado en gráficos con interfaz moderna."
        }
      ],
      "mainAction": "Empieza ahora",
      "mainActionSide": "Todo en un solo lugar",
      "notebook": "Notebook",
      "screen": "Pantalla",
      "learnFeatures": "Más información sobre las funciones de Lobbyze",
      "seeDemo": "Ver demo",
      "featuresList": [
        {
          "title": "Lista completa de torneos de los sitios web más grandes del mundo",
          "description": "Te traemos una lista completa de los sitios más grandes del mundo para que puedas construir tu cuadrícula de tornamentos extrayendo lo mejor de cada habitación en tu perfil de juego."
        },
        {
          "title": "Cuadrícula del torneo",
          "description": "Crea un calendario de torneos semanal y configura notificaciones para que no olvides registrarte en tus torneos favoritos."
        },
        {
          "title": "Crea filtros personalizados según tus necesidades",
          "description": "Crea filtros personalizados para agilizar la selección de torneos que compondrán tu cuadrícula."
        },
        {
          "title": "Gestión financiera de su rutina",
          "description": "Realiza un seguimiento de los torneos jugados, los premios recibidos y controla tus ganancias y pérdidas."
        }
      ],
      "intro": "Vea cómo se ve nuestra plataforma desde el interior y comience a usarla hoy mismo",
      "introPlay": "Lorem ipsum dolor sit amet torquent consectetur adipiscing elit",
      "ourPlans": "Nuestros Planes",
      "chooseBestPlan": "Elige el mejor plan para ti",
      "startNow": "Empieza ahora",
      "pricePerMonth": "<span>$</span><b>{{price}} <span>/ mes</span></b>",
      "queriesPerMonth": "<b>{{count}}</b> consulta por mes",
      "queriesPerMonth_plural": "<b>{{count}}</b> consultas al mes",
      "questionContactUs": "¿Alguna pregunta?<br />¡Ponte en contacto con nosotros!",
      "name": "Nombre",
      "email": "Correo electrónico",
      "message": "Mensaje",
      "phone": "Teléfono",
      "send": "Enviar",
      "copyright": "Todos los derechos reservados. {{year}} de Lobbyze ©",
      "privacyPolicy": "Política de Privacidad",
      "faq": "FAQ",
      "frequentlyAskedQuestions": "Preguntas Frecuentes",
      "faqList": [
        {
          "title": "¿La licencia se renueva automáticamente?",
          "description": "Sí. La licencia se va a renovar hasta que el usuario decida cancelarla. Para cancelar su suscripción, busque el menú de usuario y haga clic en suscripción."
        },
        {
          "title": "¿Lobbyze enumera todos los torneos de todas las categorías?",
          "description": "No. Por ahora enumeramos solo los torneos texas holde'm, y debido a que la lista no se genera a partir de las salas de torneos en sí, existe la posibilidad de que falten torneos, sin embargo, todos los torneos principales de las salas mencionadas anteriormente se enumeran en la plataforma."
        },
        {
          "title": "¿Puedo usar una licencia compartida?",
          "description": "No. La licencia es única por usuario."
        }
      ],
      "clients": "Clientes",
      "our_clients": "Nuestros clientes",
      "clientsTrust": "Con la confianza de muchos equipos",
      "supported_sites": "Sitios compatibles"
    }
  }