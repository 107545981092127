import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { supportedLanguages } from '../i18n';

import classNames from 'classnames';

import logoLobbyze from 'url:../assets/logo_lobbyze.svg';
import _888poker from 'url:../assets/888-poker.png';
import email from 'url:../assets/email.svg';
import iconCheck from 'url:../assets/selecionado.svg';
import iconClose from 'url:../assets/excluir.svg';
import iconCart from 'url:../assets/cart.png';
import iconEnter from 'url:../assets/enter.png';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/sea/index.css';

const Shop = () => {
  const { t, i18n } = useTranslation();

  const [fixedNavbar, setFixedNavbar] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const [showLanguageTop, setShowLanguageTop] = useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleChangeLanguage = (lang) => () => {
    i18n.changeLanguage(lang);
  };

  const handleActiveTab = (index) => () => {
    setActiveTab(index);
  };

  const toggleLanguageMenuTop = () => {
    setShowLanguageTop((oldValue) => !oldValue);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((oldValue) => !oldValue);
  };

  useEffect(() => {
    const listener = () => {
      if (document.body.scrollTop > 64 || document.documentElement.scrollTop > 64) {
        if (!fixedNavbar) setFixedNavbar(true);
      } else {
        if (fixedNavbar) setFixedNavbar(true);
      }
    };

    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  return (
    <div className={classNames({ 'o-hidden': modalOpen || mobileMenuOpen })}>
      <nav className={classNames('navbar', { fixed: fixedNavbar })}>
        <div className="container">
          <a className="navbar__logo" href="/">
            <img src={logoLobbyze} alt={t('logoLobbyze')} />
          </a>
          <aside className={classNames('navbar__links', { open: mobileMenuOpen })}>
            <ul className="navbar__links__list">
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="/#features">{t('features')}</a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="/plans">{t('pricing')}</a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="/#contato">{t('contact')}</a>
              </li>
              <li className="navbar__links__list__link">
                <a href="/faq">
                  {t('FAQ')}
                </a>
              </li>
              <li className="navbar__links__list__link link_desafio">
                <a href="/desafio10k">
                  Desafio 10k
                </a>
              </li>
              <li className="navbar__links__list__criar-conta">
                <a href="https://app.lobbyze.com/">
                  {t('tryItFree')}
                </a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="https://app.lobbyze.com">{t('login')}</a>
              </li>
              <li
                className={classNames('navbar__links__list__language', { open: showLanguageTop })}
                onClick={toggleLanguageMenuTop}
              >
                <button className="navbar__links__list__language__atual" type="button">
                  <span>{i18n.language}</span>
                </button>
                <div className="navbar__links__list__language__outros">
                  {supportedLanguages
                    .filter((lang) => lang != i18n.language)
                    .map((lang) => (
                      <a key={lang} onClick={handleChangeLanguage(lang)}>
                        {lang}
                      </a>
                    ))}
                </div>
              </li>
            </ul>
          </aside>
          <button
            className={classNames('navbar__menu-icon', { open: mobileMenuOpen })}
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="navbar__menu-icon__bar"></span>
            <span className="navbar__menu-icon__bar"></span>
            <span className="navbar__menu-icon__bar"></span>
          </button>
        </div>
      </nav>

      <section className="plans-sec">
        <section id="plans" className="plans">
          <div>
            <div className="background">
                <div className="plans-box">
                    <div className="plans-container">
                        <div className="row">
                            <div className="col px-0">
                              <div className="tier-box-container">
                                <div className="tier-box">
                                    <h5 className="tier-title free">TRIAL</h5>
                                    <div className="tier-price free">
                                        <div className="dis_ribbon">
                                            <span>{t('free_highlight')}</span>
                                        </div>
                                        <span>{t("tier_box_free_4")}</span><br/> {t('during_7_days')}
                                    </div>
                                    <div className="tier-info">
                                        <div className="tier_description" style={{fontWeight: 'normal'}}>
                                          <img src={iconCheck} alt="Check" />{t('full_description_1')}
                                        </div>
                                        <div className="tier_description" style={{fontWeight: 'normal'}}>
                                          <img src={iconCheck} alt="Check" />{t('full_description_2')}
                                        </div>
                                        <div className="tier_description" style={{fontWeight: 'normal'}}>
                                          <img src={iconCheck} alt="Check" />{t('full_description_3')}
                                        </div>
                                        <div className="tier_description" style={{fontWeight: 'normal'}}>
                                          <img src={iconCheck} alt="Check" />{t('full_description_4')}
                                        </div>
                                        <div className="tier_description" style={{fontWeight: 'normal'}}>
                                          <img src={iconCheck} alt="Check" />{t('full_description_5')}
                                        </div>
                                        <div className="tier_description" style={{fontWeight: 'normal'}}>
                                          <img src={iconCheck} alt="Check" />{t('full_description_6')}
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div className="button-container">
                                    <a href='https://app.lobbyze.com'>
                                        <div className="tier-button">
                                          <img src={iconEnter} alt="Cart" className="register-icon"/><span>{t('register')}</span>
                                        </div>
                                    </a>
                              </div>
                            </div>
                            <div className="col px-0">
                                <div className="tier-box-container">
                                    <div className="tier-box">
                                        <h5 className="tier-title small">SMALL</h5>
                                        <div className="tier-price small">
                                            {t('tier_box_from')}<br/>
                                            {t('tier_box_of')}<span>$4,49</span>{t('tier_box_month')}
                                            <p>{t('yearly_plan')}</p>
                                        </div>
                                        <div className="tier-info">
                                            <div className="tier_description" style={{fontWeight: 600}}>
                                              <img src={iconCheck} alt="Check" />{t('small_description_1')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('small_description_2')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('small_description_3')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('small_description_4')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('small_description_5')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('small_description_6')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <a href='https://app.lobbyze.com/buy/small'>
                                        <div className="tier-button">
                                          <img src={iconCart} alt="Cart" /><span>{t('buy')}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col px-0">
                                <div className="tier-box-container">
                                    <div className="tier-box">
                                        <h5 className="tier-title full">FULL</h5>
                                        <div className="tier-price full">
                                            {t('tier_box_from')}<br/>
                                            {t('tier_box_of')}<span>$7,48</span>{t('tier_box_month')}
                                            <p>{t('yearly_plan')}</p>
                                        </div>
                                        <div className="tier-info">
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('full_description_1')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('full_description_2')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('full_description_3')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('full_description_4')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('full_description_5')}
                                            </div>
                                            <div className="tier_description" style={{fontWeight: 'normal'}}>
                                              <img src={iconCheck} alt="Check" />{t('full_description_6')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <a href='https://app.lobbyze.com/buy/full'>
                                        <div className="tier-button">
                                            <img src={iconCart} alt="Cart" /><span>{t('buy')}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </section>

      <footer id="contato" className="footer">
        <header className="header-sec">
          <div className="container">
            <h5>{t('contact')}</h5>
            <h3>
              <Trans i18nKey="questionContactUs" components={{ br: <br /> }} />
            </h3>
          </div>
        </header>
        <div className="footer__content container">
          <aside className="footer__form">
            <form action="/">
              <fieldset>
                <input required id="nome" type="text" />
                <label htmlFor="nome">{t('name')}</label>
              </fieldset>
              <fieldset>
                <input required id="email" type="email" />
                <label htmlFor="email">{t('email')}</label>
              </fieldset>
              <fieldset>
                <textarea required id="mensagem" />
                <label htmlFor="mensagem">{t('message')}</label>
              </fieldset>
              <button className="button" type="submit">
                {t('send')}
              </button>
            </form>
          </aside>
          <aside className="footer__infos">
            <header className="footer__infos__header">
              <a href="index.html">
                <img src={logoLobbyze} alt="Logo Lobbyze" />
              </a>
              <div>
                <div className="footer__infos__others__redes">
                  <a title="Instagram" href="#">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.0083 5.21901C7.34165 5.21901 5.19068 7.37045 5.19068 10.0377C5.19068 12.705 7.34165 14.8565 10.0083 14.8565C12.675 14.8565 14.826 12.705 14.826 10.0377C14.826 7.37045 12.675 5.21901 10.0083 5.21901ZM10.0083 13.1705C8.28505 13.1705 6.87623 11.7656 6.87623 10.0377C6.87623 8.30987 8.28086 6.90493 10.0083 6.90493C11.7358 6.90493 13.1404 8.30987 13.1404 10.0377C13.1404 11.7656 11.7316 13.1705 10.0083 13.1705ZM16.1467 5.0219C16.1467 5.64678 15.6436 6.14585 15.0231 6.14585C14.3983 6.14585 13.8994 5.64259 13.8994 5.0219C13.8994 4.40121 14.4025 3.89795 15.0231 3.89795C15.6436 3.89795 16.1467 4.40121 16.1467 5.0219ZM19.3376 6.16262C19.2663 4.65703 18.9225 3.32339 17.8197 2.2246C16.7212 1.12582 15.3878 0.781923 13.8826 0.706434C12.3312 0.618363 7.68127 0.618363 6.1299 0.706434C4.62884 0.777729 3.29549 1.12162 2.19276 2.22041C1.09002 3.3192 0.750397 4.65284 0.674925 6.15843C0.586874 7.71015 0.586874 12.3611 0.674925 13.9128C0.746204 15.4184 1.09002 16.7521 2.19276 17.8509C3.29549 18.9496 4.62464 19.2935 6.1299 19.369C7.68127 19.4571 12.3312 19.4571 13.8826 19.369C15.3878 19.2977 16.7212 18.9538 17.8197 17.8509C18.9183 16.7521 19.2621 15.4184 19.3376 13.9128C19.4256 12.3611 19.4256 7.71434 19.3376 6.16262ZM17.3333 15.5778C17.0063 16.3998 16.3732 17.0331 15.5472 17.3644C14.3103 17.8551 11.3752 17.7418 10.0083 17.7418C8.64145 17.7418 5.70222 17.8509 4.46951 17.3644C3.6477 17.0373 3.01457 16.404 2.68333 15.5778C2.19276 14.3406 2.30597 11.4049 2.30597 10.0377C2.30597 8.67054 2.19695 5.73066 2.68333 4.49767C3.01037 3.67567 3.6435 3.0424 4.46951 2.71109C5.70641 2.22041 8.64145 2.33364 10.0083 2.33364C11.3752 2.33364 14.3145 2.2246 15.5472 2.71109C16.369 3.03821 17.0021 3.67148 17.3333 4.49767C17.8239 5.73485 17.7107 8.67054 17.7107 10.0377C17.7107 11.4049 17.8239 14.3448 17.3333 15.5778Z"
                        fill="#838CFF"
                      />
                    </svg>
                  </a>
                  <a title="Twitter" href="#">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9799 4.75279C17.9924 4.93966 17.9924 5.12657 17.9924 5.31344C17.9924 11.0132 13.9404 17.5807 6.53456 17.5807C4.25296 17.5807 2.13347 16.8732 0.350586 15.6452C0.674757 15.6852 0.986418 15.6986 1.32306 15.6986C3.20565 15.6986 4.93869 15.0178 6.3226 13.8565C4.55219 13.8164 3.06853 12.575 2.55735 10.8664C2.80672 10.9064 3.05606 10.9331 3.3179 10.9331C3.67946 10.9331 4.04104 10.8797 4.37765 10.7863C2.53244 10.3858 1.14848 8.65056 1.14848 6.55485V6.50147C1.68457 6.82184 2.30801 7.02206 2.96875 7.04873C1.88406 6.2745 1.17343 4.95301 1.17343 3.45797C1.17343 2.65707 1.37288 1.9229 1.72199 1.28217C3.70436 3.89848 6.68415 5.60706 10.0255 5.79397C9.96313 5.4736 9.92572 5.13992 9.92572 4.8062C9.92572 2.43014 11.7211 0.494629 13.9528 0.494629C15.1123 0.494629 16.1595 1.01522 16.8952 1.85618C17.8053 1.66931 18.678 1.30888 19.451 0.814995C19.1518 1.81616 18.5159 2.65711 17.6806 3.19102C18.491 3.09762 19.2765 2.8573 19.9996 2.52362C19.4511 3.37789 18.7653 4.13872 17.9799 4.75279Z"
                        fill="#838CFF"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </header>
            <section className="footer__infos__others">
              <div className="footer__infos__others__contents">
                <div className="footer__infos__others__contents__content">
                  <figure>
                    <img src={email} alt="ícone e-mail" />
                  </figure>
                  <div>
                    <h5>{t('email')}</h5>
                    <span>support@lobbyze.com</span>
                  </div>
                </div>
              </div>
            </section>
          </aside>
        </div>
        <h6 className="footer__obs">
          {t('copyright', { year: new Date().getFullYear() })}
          <a href="/privacy_policy.html" target="_blank" className="footer__privacy">{t('privacyPolicy')}</a>
        </h6>
      </footer>
    </div>
  );
};

export default Shop;
