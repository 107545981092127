import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

const ContactForm = () => {
  const { t, i18n } = useTranslation();

  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ message, setMessage ] = useState('')

  const [feedback, setFeedback] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (name == '' || email == '' || message == ''){
      setFeedback("There are empty fields")
    } else {
      const formData = new FormData();

      formData.append('email', email);
      formData.append('subject', name);
      formData.append('content', message);
      formData.append('redirect', 'false');

      fetch('https://test.lobbyze.com/api/v2/mail/contact',
        { method: 'POST',
          body: formData
        }).then(async response => {
          if (!response.ok) {
            console.log(response)
            throw new Error(`Error! status: ${response.status}`)
          } else {
            setTimeout(() => {
              setFeedback("Thank you! We'll be in touch soon.")
            }, 100);
          }
      })
      .catch(error => {
          setFeedback("There was an unexpected error. Please try again later.");
          console.log('There was an error!', error);
      });
    }

  };

  return (
    <form onSubmit={handleSubmit} method="POST">
        <fieldset>
            <input required id="nome" type="text" value={name}
            onChange={(e) => setName(e.target.value)} />
            <label htmlFor="nome">{t('name')}</label>
        </fieldset>
        <fieldset>
            <input required id="email" type="email" value={email}
            onChange={(e) => setEmail(e.target.value)} />
            <label htmlFor="email">{t('email')}</label>
        </fieldset>
        <fieldset>
            <textarea required id="mensagem" value={message}
            onChange={(e) => setMessage(e.target.value)} />
            <label htmlFor="mensagem">{t('message')}</label>
        </fieldset>
        <button className="button" type="submit">
            {t('send')}
        </button>
        <div>{ feedback }</div>
    </form>
  );
};

export default ContactForm;
